import {
  ApartmentOutlined,
  BuildOutlined,
  ChromeOutlined,
  FilePptOutlined,
  HomeOutlined,
  LoginOutlined,
  LogoutOutlined,
  MoneyCollectOutlined,
  ShoppingCartOutlined,
  UserAddOutlined,
  UserOutlined,
  VerifiedOutlined,
} from "@ant-design/icons";
import { Layout, Menu } from "antd";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";

import { isGBAVent } from "@app-constants/common";
import { PATHS } from "@app-constants/routes";

import MTLogoSvg from "../svg/MTLogoSvg";

const { Sider } = Layout;

export default function Sidebar({
  isCollapsed,
  onCollapse,
}: {
  isCollapsed?: boolean;
  onCollapse?: (value: boolean) => void;
}) {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const items = [
    {
      key: "/",
      label: (
        <Link to="/">
          <HomeOutlined /> <span>{t("sidebar.dashboard")}</span>
        </Link>
      ),
    },
    {
      key: "/users",
      label: (
        <Link to="/users">
          <UserOutlined /> <span>{t("sidebar.users")}</span>
        </Link>
      ),
    },
    {
      key: "/rings",
      label: (
        <Link to="/rings">
          <ChromeOutlined /> <span>{t("sidebar.rings")}</span>
        </Link>
      ),
    },
    {
      key: "/products",
      label: (
        <Link to="/products">
          <FilePptOutlined />
          <span>{t("sidebar.products")}</span>
        </Link>
      ),
    },
    {
      key: "/orders",
      label: (
        <Link to="/orders">
          <ShoppingCartOutlined /> <span>{t("sidebar.orders")}</span>
        </Link>
      ),
    },
    {
      key: "/ekyc",
      label: (
        <Link to="/ekyc">
          <VerifiedOutlined /> <span>{t("sidebar.ekyc")}</span>
        </Link>
      ),
    },
    {
      key: "/building",
      label: (
        <Link to="/building">
          <ApartmentOutlined /> <span>{t("sidebar.building")}</span>
        </Link>
      ),
    },
  ];

  return (
    <Sider
      trigger={null}
      // collapsible
      collapsed={isCollapsed}
      breakpoint="lg"
      collapsedWidth="50"
      onBreakpoint={(broken) => {
        console.log(broken);
      }}
      onCollapse={(collapsed, type) => {
        console.log(collapsed, type);
        onCollapse?.(type === "responsive");
      }}
    >
      <div className="logo px-2 h-[64px] flex items-center justify-center">
        <MTLogoSvg />
      </div>
      <Menu
        theme="dark"
        mode="inline"
        selectedKeys={[pathname]}
        items={items}
      />
    </Sider>
  );
}
