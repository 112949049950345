import { Button, Form, Input, Modal, Select, message } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { IItem } from "../../../interface/item";
import itemServices from "../../../services/itemServices";

export default function AddOrEditRingModal({
  isOpen,
  itemData,
  onSuccess,
  onCancel,
}: {
  isOpen?: boolean;
  itemData?: IItem | null;
  onSuccess?: () => void;
  onCancel?: () => void;
}) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (itemData) {
      // setData(itemData);
      form.setFieldsValue(itemData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemData]);

  useEffect(() => {
    return () => {
      form.resetFields();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const handleFinish = async (values: any) => {
    setLoading(true);
    let response = null;
    try {
      if (itemData?.id) {
        response = await itemServices.updateItem(itemData.id as number, values);
      } else {
        response = await itemServices.createItem(values);
      }
      onSuccess?.();
    } catch (error: any) {
      message.error(t(error));
    } finally {
      setLoading(false);
    }
  };
  const requiredRules = [
    {
      required: true,
      message: t("form.requiredField.required"),
    },
  ];

  return (
    <Modal
      title={itemData ? t("title.editRing") : t("title.createRing")}
      open={isOpen}
      onCancel={onCancel}
      footer={null}
    >
      <Form layout="vertical" form={form} onFinish={handleFinish}>
        {!!itemData && (
          <Form.Item
            label={t("item.eid")}
            name="eid"
            required
            rules={requiredRules}
          >
            <Input placeholder={t("form.common.placeholder")} />
          </Form.Item>
        )}
        {itemData && (
          <Form.Item
            label={t("item.serialNo")}
            name="serialNo"
            required
            rules={requiredRules}
          >
            <Input placeholder={t("form.common.placeholder")} />
          </Form.Item>
        )}
        <Form.Item label="UID" name="uid" required rules={requiredRules}>
          <Input placeholder={t("form.common.placeholder")} />
        </Form.Item>
        {itemData && (
          <Form.Item className="flex-1" label={t("item.status")} name="status">
            <Select>
              <Select.Option value="NOT_DONE">NOT_DONE</Select.Option>
              <Select.Option value="DONE">DONE</Select.Option>
            </Select>
          </Form.Item>
        )}
        <Form.Item label={t("item.label")} name="label">
          <Input placeholder={t("form.common.placeholder")} />
        </Form.Item>
        <Form.Item className="flex justify-end">
          <Button onClick={onCancel} className="mr-4">
            {t("button.cancel")}
          </Button>
          <Button type="primary" htmlType="submit" loading={loading}>
            {t("button.submit")}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}
