import {
  CloseCircleFilled,
  MinusCircleOutlined,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import {
  AutoComplete,
  Button,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  message,
} from "antd";
import Papa from "papaparse";
import { ChangeEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { IItem } from "@app-interface/item";
import itemServices from "@app-services/itemServices";
import useService from "@app-services/shared/use_service";

const useBulkItem = () => useService(itemServices.bulk);

export const options = Array(11)
  .fill(0)
  .map((_, index: number) => ({
    label: `Ring size ${index + 5}`,
    value: `${5 + index}`,
  }));

export default function GenerateRingsModal({
  isOpen,
  onSuccess,
  onCancel,
}: {
  isOpen?: boolean;
  itemData?: IItem | null;
  onSuccess?: () => void;
  onCancel?: () => void;
}) {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [payload, bulk, clearPayload] = useBulkItem();
  const [jsonData, setJsonData] = useState<any>(null);
  const [showData, setShowData] = useState(false);
  const [fileName, setFileName] = useState("");

  useEffect(() => {
    return () => {
      clearPayload();
      form?.resetFields();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    if (payload.success) {
      onSuccess?.();
      onCancel?.();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payload.success]);

  useEffect(() => {
    if (payload.error) {
      message.error(t(payload.error));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payload.error]);

  const handleFinish = async (values: any) => {
    if (!jsonData) {
      return;
    }
    const total = jsonData.length;
    const newIds = jsonData
      .filter((itm: any) => itm.id || itm.ID || itm.Id)
      .map((itm: any) => itm.id || itm.ID || itm.Id);
    if (!newIds) {
      message.error(t("message.unableConvertJson"));
      return;
    }

    if (total !== newIds.length) {
      message.error(t("message.jsonInconsistent"));
      return;
    }
    const ringQuantity = values.items.reduce(
      (rs: number, itm: { quantity: number }) => rs + itm.quantity,
      0,
    );
    if (total !== ringQuantity) {
      message.error(
        `Ring quantity (${ringQuantity}) is mismatch with total IDs (${total}). Please check again`,
      );
      return;
    }

    const newVal = values.items.map((item: any) => ({
      ...item,
      size: Number(item.size),
    }));

    await bulk({ items: newVal, ids: newIds });
  };

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e?.target?.files?.[0]) {
      setFileName(e.target.files[0].name);
      Papa.parse(e.target?.files[0], {
        header: true,
        complete: (results) => {
          console.log(results);

          setJsonData(results.data);
        },
        error(error, file) {
          message.error(error.message);
        },
      });
    }
  };
  const onToggleDisplayData = () => {
    setShowData((prev) => !prev);
  };

  const handleOnClearFile = () => {
    setFileName("");
    setJsonData(null);
  };

  return (
    <Modal
      title={t("title.generateRing")}
      open={isOpen}
      onCancel={onCancel}
      footer={null}
      className="w-[90%] md:w-[60%]"
      width={800}
    >
      <div className="flex row justify-between items-center py-4">
        <Button
          ghost
          type="primary"
          danger={!jsonData}
          icon={<UploadOutlined />}
          className="relative"
        >
          {t("button.importIds")}
          <input
            type="file"
            id="upload-csv"
            accept={".csv"}
            onChange={handleOnChange}
            title="Upload ID list"
            className="opacity-0 absolute inset-0 z-30"
          />
        </Button>
        {jsonData && (
          <div className="flex flex-wrap lg:flex-nowrap row gap-3 items-center">
            <Button
              shape="round"
              className="text-sm font-semibold p-0 h-8 w-fit flex row gap-2 items-center"
            >
              <div>{fileName} - </div>
              {t("content.total")}: {jsonData.length} ids
              <CloseCircleFilled
                onClick={handleOnClearFile}
                color="red"
                className="text-red-500 text-lg leading-[0]"
              />
            </Button>

            <Button className="ml-4" onClick={onToggleDisplayData}>
              {t("button.previewIds")}
            </Button>
          </div>
        )}
      </div>
      {showData && (
        <Modal
          open={showData}
          title="data"
          onCancel={onToggleDisplayData}
          footer={null}
          width={800}
          className="w-[90%] md:w-[60%]"
        >
          <div>
            {jsonData.map((data: any, index: number) => (
              <div key={index}>{JSON.stringify(data, null, 2)}</div>
            ))}
          </div>
        </Modal>
      )}
      <Form
        disabled={payload.loading}
        layout="vertical"
        form={form}
        onFinish={handleFinish}
        initialValues={{ items: [{ quantity: null, size: null }] }}
      >
        <Form.List name="items">
          {(fields, { add, remove }) => {
            return (
              <div>
                {fields.map((field, index) => (
                  <div key={index}>
                    <div className="flex md:flex-row flex-col gap-y-2 gap-x-4">
                      <Form.Item
                        className="flex-1"
                        label={t("form.quantity.label")}
                        name={[field.name, "quantity"]}
                        rules={[
                          {
                            required: true,
                            message: t("form.quantity.requireRule"),
                          },

                          {
                            type: "number",
                            min: 1,
                            message: t("form.quantity.numberRule"),
                          },
                        ]}
                      >
                        <InputNumber
                          className="w-full"
                          placeholder={t("form.common.placeholder")}
                          type="number"
                        />
                      </Form.Item>
                      <Form.Item
                        className="flex-1"
                        label={t("form.ringSize.label")}
                        name={[field.name, "size"]}
                        rules={[
                          {
                            required: true,
                            message: t("form.ringSize.requireRule"),
                          },
                          {
                            message: t("form.ringSize.numberRule"),
                            type: "number",
                            min: 5,
                            max: 15,
                            transform: (value) => {
                              return Number(value);
                            },
                          },
                        ]}
                      >
                        <AutoComplete allowClear options={options}>
                          <Input placeholder={t("form.common.placeholder")} />
                        </AutoComplete>
                      </Form.Item>

                      <div style={{ width: 20, marginTop: 36 }}>
                        {fields.length > 1 ? (
                          <MinusCircleOutlined
                            style={{ color: "red" }}
                            color="red"
                            onClick={() => remove(field.name)}
                          />
                        ) : null}
                      </div>
                    </div>
                  </div>
                ))}
                <Form.Item className="flex justify-end">
                  <Button
                    type="dashed"
                    onClick={() => add({ size: null, quantity: null })}
                  >
                    <PlusOutlined /> {t("button.add")}
                  </Button>
                </Form.Item>
              </div>
            );
          }}
        </Form.List>
        <Divider />
        <Form.Item className="flex justify-end">
          <Button onClick={onCancel} className="mr-4" loading={payload.loading}>
            {t("button.cancel")}
          </Button>
          <Button type="primary" htmlType="submit" loading={payload.loading}>
            {t("button.submit")}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}
