import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useRegisterNfcUsb from "src/hooks/useRegisterNfcUsb";

import NFCSvg from "@app-components/svg/NFCSvg";

export default function ScanRing({
  onChange,
}: {
  onChange: (ringId: string) => void;
}) {
  const { uidList } = useRegisterNfcUsb();
  const [current, setCurrent] = useState<null | string>(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (uidList.length && current !== uidList[uidList.length - 1]) {
      onChange(uidList[uidList.length - 1]);
      setCurrent(uidList[uidList.length - 1]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uidList]);

  return (
    <div className="flex items-center justify-center w-full h-full">
      <div className="size-[400px] p-[16px] flex items-center justify-center flex-col bg-white rounded-2xl shadow-sm">
        <NFCSvg />

        <div className="pt-6 text-[20px] font-semibold">
          {t("content.scanRing")}
        </div>
      </div>
    </div>
  );
}
