import { EditOutlined } from "@ant-design/icons";
import {
  Button,
  DatePicker,
  Input,
  Select,
  Space,
  Table,
  TableProps,
  message,
} from "antd";
import dayjs from "dayjs";
import { Key, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { IBuildingItem } from "@app-interface/building";
import buildingServices from "@app-services/buildingServices";
import useService from "@app-services/shared/use_service";

const useBuildingDetail = () => useService(buildingServices.getBuildingDetail);
const useUpdateBuilding = () => useService(buildingServices.updateBuilding);

const useBuildingItems = () => useService(buildingServices.getBuildingItems);
const useBulkItemUpdate = () => useService(buildingServices.bulkItemUpdate);

export default function BuildingDetailPage() {
  const [buildingResponse, getBuildingDetail] = useBuildingDetail();
  const [updateBuildingResponse, updateBuilding] = useUpdateBuilding();
  const [buildingItemsResponse, getBuildingItems] = useBuildingItems();
  const [bulkItemUpdateResponse, bulkItemUpdate] = useBulkItemUpdate();

  const [showEdit, setShowEdit] = useState(false);
  const [buildingName, setBuildingName] = useState("");
  const [status, setStatus] = useState<"registered" | "not_registered" | "">(
    "",
  );

  const [newStatus, setNewStatus] = useState<"registered" | "not_registered">(
    "registered",
  );

  const [date, setDate] = useState([dayjs().subtract(7, "days"), dayjs()]);

  const { id } = useParams();

  const handleTableChange = (tableData: any) => {
    const newMetadata = {
      limit: buildingItemsResponse.data.metadata?.limit,
      offset:
        (tableData.current - 1) * +buildingItemsResponse?.data.metadata?.limit,
    };
    handleGetBuildingItems(newMetadata);
    console.log("newMetadata", newMetadata);
  };

  const handleGetBuildingItems = (metadata?: any) => {
    getBuildingItems(id as string, {
      status: status === "" ? undefined : status,
      start: date?.[0]?.toISOString(),
      end: date?.[1]?.toISOString(),
      limit: 10,
      offset: 0,
      ...metadata,
    });
  };

  useEffect(() => {
    getBuildingDetail(id as string);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setSelectedRowKeys([]);
    handleGetBuildingItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, date]);

  useEffect(() => {
    if (updateBuildingResponse.success) {
      getBuildingDetail(id as string);
      setShowEdit(false);
      setBuildingName("");
      message.success("Update building success");
    } else if (updateBuildingResponse.error) {
      message.error(updateBuildingResponse.error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateBuildingResponse.success, updateBuildingResponse.error]);

  useEffect(() => {
    if (bulkItemUpdateResponse.success) {
      handleGetBuildingItems();
      message.success("Update items success");
      setSelectedRowKeys([]);
    } else if (bulkItemUpdateResponse.error) {
      message.error(bulkItemUpdateResponse.error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bulkItemUpdateResponse.success, bulkItemUpdateResponse.error]);

  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);

  const rowSelection: TableProps<IBuildingItem>["rowSelection"] = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: IBuildingItem[]) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows,
      );
      setSelectedRowKeys(selectedRowKeys);
    },
    getCheckboxProps: (record: IBuildingItem) => ({
      name: record.id + "",
    }),
    selectedRowKeys,
  };

  const handleBulkUpdate = () => {
    bulkItemUpdate(id as string, {
      itemIds: selectedRowKeys.map((key) => key as number),
      status: newStatus,
    });
  };

  const onChange: any = (date: any, dateString: string) => {
    console.log(date, dateString);
    setDate(date);
  };
  return (
    <div>
      <div className="font-bold text-[30px]">
        {showEdit ? (
          <Space>
            <Input
              value={buildingName}
              onChange={(e) => setBuildingName(e.target.value)}
            />
            <Button
              type="primary"
              loading={updateBuildingResponse.loading}
              onClick={() =>
                updateBuilding(buildingResponse.data?.id, {
                  name: buildingName,
                })
              }
            >
              Update
            </Button>
            <Button
              disabled={updateBuildingResponse.loading}
              onClick={() => {
                setShowEdit(false);
                setBuildingName("");
              }}
            >
              Cancel
            </Button>
          </Space>
        ) : (
          <>
            {buildingResponse.data?.name}{" "}
            <EditOutlined
              onClick={() => {
                setShowEdit(true);
                setBuildingName(buildingResponse?.data?.name);
              }}
            />
          </>
        )}
      </div>
      <br />
      <div className="flex row justify-between pb-4 flex-wrap gap-2">
        {selectedRowKeys.length ? (
          <div className="flex row items-center">
            <div>New Status:</div>
            <div className="pl-2">
              <Select
                style={{ width: 160, textAlign: "left" }}
                placeholder="Select new status"
                value={newStatus}
                onChange={(value) => {
                  setNewStatus(value);
                }}
                options={[
                  {
                    value: "not_registered",
                    label: "Not Registered",
                  },
                  {
                    value: "registered",
                    label: "Registered",
                  },
                ]}
              />
            </div>
            <Button className="ml-3" type="primary" onClick={handleBulkUpdate}>
              Update
            </Button>
          </div>
        ) : (
          <div />
        )}
        <Space>
          <div className="text-right">
            <Select
              allowClear
              style={{ minWidth: 160, textAlign: "left" }}
              placeholder="Please select"
              value={status}
              onChange={(value) => {
                setStatus(value);
              }}
              options={[
                {
                  value: "",
                  label: "All",
                },
                {
                  value: "not_registered",
                  label: "Not Registered",
                },
                {
                  value: "registered",
                  label: "Registered",
                },
              ]}
            />
          </div>
          <DatePicker.RangePicker
            onChange={onChange}
            defaultValue={[dayjs().subtract(7, "days"), dayjs()]}
            value={date as any}
          />
        </Space>
      </div>
      <div>
        <Table<IBuildingItem>
          rowKey={(row) => row.id}
          rowSelection={{ type: "checkbox", ...rowSelection }}
          loading={
            buildingItemsResponse.loading || bulkItemUpdateResponse.loading
          }
          columns={[
            {
              title: "Ring UID",
              dataIndex: ["item", "uid"],
              key: "item.uid",
            },
            {
              title: "Status",
              dataIndex: "status",
              key: "status",
            },
          ]}
          dataSource={buildingItemsResponse?.data?.models}
          onChange={handleTableChange}
          scroll={{ x: 800 }}
          pagination={{
            total: buildingItemsResponse?.data?.metadata?.count || 0,
            pageSize: buildingItemsResponse?.data?.metadata?.limit || 0,
            current:
              (buildingItemsResponse?.data?.metadata?.offset || 0) /
                (buildingItemsResponse?.data?.metadata?.limit || 0) +
              1,
          }}
        />
      </div>
    </div>
  );
}
