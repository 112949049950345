import { IBuilding } from "@app-interface/building";
import { IResponseData } from "@app-interface/common";
import { throwErrorMessage } from "@app-utils/errorHandler";

import { baseApi } from "./common";

const getBuildingList = async (
  data: any,
): Promise<IResponseData<IBuilding>> => {
  try {
    const response = await baseApi.get(`admin/buildings`, data);

    console.log("respoonse", response);

    return response.data;
  } catch (error: any) {
    throw throwErrorMessage(error);
  }
};

interface CreateBuildingReq {
  name: string;
}

const createBuilding = async (
  data: CreateBuildingReq,
): Promise<IResponseData<any>> => {
  try {
    const response = await baseApi.post(`admin/buildings`, data);

    return response.data;
  } catch (error: any) {
    throw throwErrorMessage(error);
  }
};

const updateBuilding = async (
  id: number,
  data: any,
): Promise<IResponseData<any>> => {
  try {
    const response = await baseApi.patch(`admin/buildings/${id}`, data);

    return response.data;
  } catch (error: any) {
    throw throwErrorMessage(error);
  }
};

const getBuildingDetail = async (id: string): Promise<IBuilding> => {
  try {
    const response = await baseApi.get(`admin/buildings/${id}`);

    return response.data;
  } catch (error: any) {
    throw throwErrorMessage(error);
  }
};

const getBuildingItems = async (
  id: string,
  params: any,
): Promise<IResponseData<any>> => {
  try {
    const response = await baseApi.get(`admin/buildings/${id}/items`, {
      params,
    });

    return response.data;
  } catch (error: any) {
    throw throwErrorMessage(error);
  }
};

interface BulkUpdateBuildingReq {
  itemIds: number[];
  status: "registered" | "not_registered";
}

const bulkItemUpdate = async (id: string, data: BulkUpdateBuildingReq) => {
  try {
    const response = await baseApi.patch(`admin/buildings/${id}/items/bulk`, data);

    return response.data;
  } catch (error: any) {
    throw throwErrorMessage(error);
  }
};

const buildingServices = {
  getBuildingList,
  createBuilding,
  updateBuilding,
  getBuildingDetail,
  getBuildingItems,
  bulkItemUpdate,
};

export default buildingServices;
