import {
  ActivateItemReq,
  BulkItemReq,
  CreateItemReq,
  IActivateItem,
  IItem,
  UpdateItemReq,
  UpdatePointRef,
} from "@app-interface/item";
import { throwErrorMessage } from "@app-utils/errorHandler";

import { baseApi } from "./common";

const getItems = async (params: any) => {
  try {
    const response = await baseApi.get(`admin/items`, {
      params,
    });
    return response.data;
  } catch (error: any) {
    throw throwErrorMessage(error);
  }
};

const createItem = async (data: CreateItemReq) => {
  try {
    const response = await baseApi.post(`admin/items`, data);
    return response.data;
  } catch (error: any) {
    throw throwErrorMessage(error);
  }
};

const updateItem = async (id: number, data: UpdateItemReq) => {
  try {
    const response = await baseApi.patch(`admin/items/${id}`, data);
    return response.data;
  } catch (error: any) {
    throw throwErrorMessage(error, true);
  }
};

const deleteItem = async (id: number) => {
  try {
    const response = await baseApi.delete(`admin/items/${id}`);
    return response.data;
  } catch (error: any) {
    throw throwErrorMessage(error);
  }
};

const getItem = async (id: string) => {
  try {
    const response = await baseApi.get(`admin/items/${id}`);
    return response.data;
  } catch (error: any) {
    throw throwErrorMessage(error);
  }
};

const getPointHistories = async (id: string) => {
  try {
    const response = await baseApi.get(`admin/items/${id}/point`);
    return response.data;
  } catch (error: any) {
    throw throwErrorMessage(error);
  }
};

const addPoints = async (data: UpdatePointRef) => {
  try {
    const response = await baseApi.post(`admin/items/points/add`, data);
    return response.data;
  } catch (error: any) {
    throw throwErrorMessage(error);
  }
};

const minusPoint = async (data: UpdatePointRef) => {
  try {
    const response = await baseApi.post("items/points/minus", data);
    return response.data;
  } catch (error: any) {
    throw throwErrorMessage(error);
  }
};

const activate = async (data: ActivateItemReq): Promise<IActivateItem> => {
  try {
    const response = await baseApi.post(`admin/items/activate`, data);
    return response.data;
  } catch (error: any) {
    throw throwErrorMessage(error);
  }
};

const bulk = async (data: BulkItemReq): Promise<IItem[]> => {
  try {
    const response = await baseApi.post(`admin/items/bulk`, data);
    return response.data;
  } catch (error: any) {
    throw throwErrorMessage(error, true);
  }
};

const itemExport = async (data: any): Promise<any> => {
  try {
    const response = await baseApi.get(`admin/items/export`, {
      params: data,
    });
    return response.data;
  } catch (error: any) {
    throw throwErrorMessage(error, true);
  }
};

const search = async (data: any): Promise<any> => {
  try {
    const response = await baseApi.get(`admin/items/search`, { params: data });
    return response.data;
  } catch (error: any) {
    throw throwErrorMessage(error);
  }
};

const itemServices = {
  getItems,
  createItem,
  updateItem,
  deleteItem,
  activate,
  getItem,
  addPoints,
  minusPoint,
  getPointHistories,
  bulk,
  export: itemExport,
  search,
};

export default itemServices;
