import { CloseCircleFilled, LinkOutlined } from "@ant-design/icons";
import { Button, Input, Modal, message } from "antd";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { IItem } from "@app-interface/item";
import itemServices from "@app-services/itemServices";
import useService from "@app-services/shared/use_service";

import ScanRing from "./ScanRing";

const useSearch = () => useService(itemServices.search);
const useUpdateItem = () => useService(itemServices.updateItem);

export default function SearchBySerialModal({
  isOpen,
  onSuccess,
  onCancel,
}: {
  isOpen?: boolean;
  itemData?: IItem | null;
  onSuccess?: () => void;
  onCancel?: () => void;
}) {
  const { t } = useTranslation();
  const [payload, onSearch, clearSearchPayload] = useSearch();
  const [updatePayload, onUpdate, clearUpdatePayload] = useUpdateItem();
  const [serialNo, setSerialNo] = useState("");
  const [enableLinkRing, setEnableLinkRing] = useState(false);
  const [uuid, setUuid] = useState<null | string>(null);

  useEffect(() => {
    if (updatePayload.success) {
      console.log(updatePayload.data);
      message.success(t("message.linkRingSuccess"));
      handleOnClear();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatePayload.success]);

  useEffect(() => {
    if (updatePayload.error) {
      message.error(t("message.linkRingFailed") + updatePayload.error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatePayload.error]);

  useEffect(() => {
    if (payload.error) {
      message.error(t(payload.error));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payload.error]);

  const handleOnSearch = () => {
    if (serialNo) {
      onSearch({ serialNo: serialNo.trim() });
    }
  };

  const handleOnClose = () => {
    onSuccess?.();
    onCancel?.();
  };

  const handleOnClear = () => {
    clearSearchPayload();
    clearUpdatePayload();
    setEnableLinkRing(false);
    setSerialNo("");
    setUuid("");
  };

  const handleOnKeyDown = (e?: any) => {
    if (e?.key === "Enter") {
      handleOnSearch();
    }
  };

  const handleOnLinkRing = () => {
    if (ring && uuid) {
      onUpdate(ring.id, { uid: uuid, status: "DONE" });
    }
  };

  const ring = useMemo(() => {
    if (payload.success && !!payload.data?.models?.length) {
      return payload.data.models[0];
    }
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payload.data, payload.success]);

  const handleOnLink = () => {
    setEnableLinkRing(true);
  };
  const handleOnReadRing = (ringId: string) => {
    setUuid(ringId);
  };

  const ringInfo = useMemo(
    () =>
      ring ? (
        <div className="flex flex-col gap-3 mt-6 mx-8">
          <div className="flex row">
            <div className="flex-1 text-lg text-center font-semibold">
              {t("title.ringInfo")}
            </div>
            <CloseCircleFilled
              className="text-red-500 text-xl cursor-pointer hover:opacity-85 transition-opacity"
              onClick={handleOnClear}
            />
          </div>
          <div className="flex flex-row gap-6">
            <div className="text-md">UID:</div>
            <div className="font-semibold">{ring.uid || "-"}</div>
          </div>
          <div className="flex flex-row gap-6">
            <div className="text-md">{t("item.serialNo")}:</div>
            <div className="font-semibold">{ring.serialNo || "-"}</div>
          </div>
          <div className="flex flex-row gap-6">
            <div className="text-md">{t("item.eid")}:</div>
            <div className="font-semibold">{ring.eid || "-"}</div>
          </div>
          <div className="flex flex-row gap-6">
            <div className="text-md">{t("item.status")}:</div>
            <div className="font-semibold">{ring.status || "-"}</div>
          </div>
          <div className="flex flex-row gap-6">
            <div className="text-md">{t("item.size")}:</div>
            <div className="font-semibold">{ring.size || "-"}</div>
          </div>
        </div>
      ) : (
        <></>
      ),
    [ring],
  );

  return (
    <Modal
      title={t("button.findSerialNo")}
      open={isOpen}
      keyboard={false}
      footer={null}
      closable={false}
      onClose={onCancel}
      className="w-[90%] md:w-[60%]"
    >
      {enableLinkRing ? (
        <>
          <ScanRing onChange={handleOnReadRing} />
          <div className="row mt-9 flex gap-4 justify-end">
            <Button
              danger
              ghost
              disabled={payload.loading}
              onClick={() => setEnableLinkRing(false)}
            >
              {t("button.cancel")}
            </Button>
            <Button
              disabled={payload.loading || !uuid}
              onClick={handleOnLinkRing}
              type="primary"
            >
              {t("button.complete")}
            </Button>
          </div>
        </>
      ) : (
        <div className=" py-4">
          <div className=" font-medium text-sm mb-1">{t("item.serialNo")}</div>
          <Input
            disabled={payload.loading}
            onChange={(e) => setSerialNo(e.target?.value)}
            onKeyDown={handleOnKeyDown}
            value={serialNo}
            placeholder={t("form.common.placeholder")}
            allowClear
          />
          {payload.success && !payload.data?.models?.length && (
            <div className="text-center mt-4 text-gray-400">
              {t("content.notfound")}
            </div>
          )}
          {ring && (
            <div className="border-[1px] border-solid border-gray-200 rounded-lg mt-4">
              {ringInfo}
              {ring.status === "NOT_DONE" && !ring.uid && (
                <div className="py-8 flex justify-center items-center">
                  <Button onClick={handleOnLink} type="primary" ghost>
                    <LinkOutlined />
                    {t("button.linkWithUid")}
                  </Button>
                </div>
              )}
            </div>
          )}

          <div className="row flex gap-4 justify-end pt-16">
            <Button
              disabled={payload.loading || !serialNo}
              onClick={handleOnSearch}
              type="primary"
            >
              {t("button.search")}
            </Button>
            <Button
              danger
              ghost
              disabled={payload.loading}
              onClick={handleOnClose}
            >
              {t("button.cancel")}
            </Button>
          </div>
        </div>
      )}
    </Modal>
  );
}
