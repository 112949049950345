import { message } from "antd";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import jwtDecode from "jwt-decode";
import { useEffect, useState } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";

import { LocalStorageKeys } from "../constants/common";
import {
  isLoggedAtom,
  permissionKeyAtom,
  tokenAtom,
  useAuthWriteOnly,
} from "../jotai/auth";
import { themeModeAtom } from "../jotai/theme/getters";
import { updateHeaderToken } from "../services/common";
import BuildingDetailPage from "./building/BuildingDetailPage";
import BuildingListPage from "./building/BuildingListPage";
import DashboardPage from "./dashboard/DashboardPage";
import EKYCPage from "./eKYC/EKYCPage";
import HomePage from "./home/HomePage";
import LoginPage from "./login/LoginPage";
import OrdersPage from "./orders/OrdersPage";
import ProductsPage from "./products/ProductsPage";
import RingsPage from "./rings/RingsPage";
import UsersPage from "./users/UsersPage";

export default function Root() {
  const theme = useAtomValue(themeModeAtom);
  const setToken = useSetAtom(tokenAtom);
  const setIsLogged = useSetAtom(isLoggedAtom);
  const [init, setInit] = useState(false);
  const { onSignout, onGetProfile } = useAuthWriteOnly();
  const [permissionKey, setPermissionKey] = useAtom(permissionKeyAtom);

  useEffect(() => {
    checkAuth();
    onGetProfile();
    setInit(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (permissionKey) {
      timeout = setTimeout(
        () => {
          setPermissionKey("");
        },
        15 * 60 * 1000,
      );
    }
    return () => {
      timeout && clearTimeout(timeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permissionKey]);

  const checkAuth = () => {
    const token = window.localStorage.getItem(LocalStorageKeys.CMS_TOKEN);
    if (!token) return;

    try {
      const decodedToken = jwtDecode(token) as any;

      const time = new Date().getTime();

      if (!decodedToken?.exp || time > decodedToken?.exp * 1000) {
        onSignout();
        return;
      }

      updateHeaderToken(token);
      setToken(token);
      setIsLogged(true);

      // const permissionKeyExpireAt = window.localStorage.getItem(
      //   LocalStorageKeys.PERMISSION_KEY_EXPIRE_AT,
      // );

      // if (
      //   permissionKeyExpireAt &&
      //   new Date().getTime() < Number(permissionKeyExpireAt)
      // ) {
      //   setPermissionKey(
      //     window.localStorage.getItem(
      //       LocalStorageKeys.PERMISSION_KEY,
      //     ) as string,
      //   );
      //   updateHeaderPermissionKey(permissionKey);
      // } else {
      //   window.localStorage.removeItem(LocalStorageKeys.PERMISSION_KEY);
      //   window.localStorage.removeItem(
      //     LocalStorageKeys.PERMISSION_KEY_EXPIRE_AT,
      //   );
      // }
    } catch (error: any) {
      message.error(error);
    }
  };

  return (
    <div className={`theme-${theme}`}>
      {init && (
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route
            path="/"
            element={
              <RequireAuth>
                <HomePage />
              </RequireAuth>
            }
          >
            <Route path="/" element={<DashboardPage />} />
            <Route path="/users" element={<UsersPage />} />
            <Route path="/rings" element={<RingsPage />} />
            <Route path="/products" element={<ProductsPage />} />
            <Route path="/orders" element={<OrdersPage />} />
            <Route path="/ekyc" element={<EKYCPage />} />
            <Route path="/building" element={<BuildingListPage />} />
            <Route path="/building/:id" element={<BuildingDetailPage />} />
          </Route>
        </Routes>
      )}
    </div>
  );
}

const RequireAuth = ({ children }: { children: any }) => {
  const isLogged = useAtomValue(isLoggedAtom);
  const location = useLocation();

  if (!isLogged) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
};
