import { Button, Form, Input, Modal, message } from "antd";
import { useEffect, useState } from "react";

import buildingServices from "@app-services/buildingServices";

import { IUser } from "../../../interface/user";

export default function AddOrEditBuildingModal({
  isOpen,
  buildingData,
  onSuccess,
  onCancel,
}: {
  isOpen?: boolean;
  buildingData?: IUser | null;
  onSuccess?: () => void;
  onCancel?: () => void;
}) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (buildingData) {
      // setData(buildingData);
      form.setFieldsValue(buildingData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buildingData]);

  useEffect(() => {
    return () => {
      form.resetFields();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const handleFinish = async (values: any) => {
    console.log("first", values);
    setLoading(true);
    let response = null;
    try {
      if (buildingData) {
        response = await buildingServices.updateBuilding(
          buildingData.id,
          values,
        );
      } else {
        response = await buildingServices.createBuilding(values);
      }
      if (!!response) {
        message.success(
          buildingData ? "Update user success" : "Create user success",
        );
        onSuccess?.();
      }
    } catch (error: any) {
      message.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      title={buildingData ? "Edit User" : "Create User"}
      open={isOpen}
      onCancel={onCancel}
      footer={null}
    >
      <Form layout="vertical" form={form} onFinish={handleFinish}>
        <Form.Item label="Name" name="name">
          <Input placeholder="Building name" />
        </Form.Item>

        <Form.Item className="flex justify-end">
          <Button onClick={onCancel} className="mr-4">
            Cancel
          </Button>
          <Button type="primary" htmlType="submit" loading={loading}>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}
